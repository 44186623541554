import { useStaticQuery, graphql } from "gatsby"

export const useLevel = () => {
  const { allStrapiLevel } = useStaticQuery(
    graphql`
      query levels {
        allStrapiLevel {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  )
  return allStrapiLevel.edges
}
