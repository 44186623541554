import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import CourseCard from "../components/home/course-card"
import { AnimatePresence } from "framer-motion"
import { useLevel } from "../hooks/use-level"

const AncienCours = () => {
  return (
    <div className="my-10 text-justify md:text-center text-gray-700">
      <h2 className="text-gray-800 font-bold mb-5 px-2">
        Vous ne trouvez pas un cours en particulier ?
      </h2>
      <p className="md:w-1/2 mx-auto px-2">
        Les programmes de l'éducation nationale évoluant régulièrement, il se
        peut que le cours consulté ne soit plus en phase avec les
        récommandations du ministère. Vous pourrez bientôt retrouver ici les
        cours des années précédentes, ou vous pouvez consulter les archives sur{" "}
        <a href="https://archives.ses-noailles.fr">archives.ses-noailles.fr</a>
      </p>
    </div>
  )
}

const CoursPage = ({ data }) => {
  const [displayedCourses, setDiplayedCourses] = useState(
    data.allStrapiCourse.edges
  )
  const [inputs, setInputs] = useState({
    level: "tous",
  })
  const [filteredCourses, setFilteredCourses] = useState(
    data.allStrapiCourse.edges
  )

  function handleChange(event) {
    setInputs({ level: event.target.value })
  }

  const allLevels = useLevel()

  const courses = data.allStrapiCourse.edges

  useEffect(() => {
    let filtered =
      inputs.level === "tous"
        ? courses
        : courses.filter(cours => cours.node.level.slug === inputs.level)
    setFilteredCourses(filtered)
    setDiplayedCourses(filtered)
  }, [inputs])

  return (
    <Layout>
      <SEO title={"Liste des cours"} />
      <div className="container">
        <h1 className={"text-4xl font-bold mt-5 px-2"}>
          Tous les cours disponibles
        </h1>
      </div>
      <div className="filters mt-5 mb-5 px-2">
        <div className="inline-block relative w-64">
          <select
            id={"level"}
            name={"level"}
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleChange}
            value={inputs.level.value}
          >
            <option value={"tous"}>Tous les niveaux</option>
            {allLevels.map(lvl => {
              return (
                <option key={lvl.node.slug} value={lvl.node.slug}>
                  {lvl.node.title}
                </option>
              )
            })}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="filterResults px-2 my-5">
        {filteredCourses.length} sur {filteredCourses.length} Cours
      </div>
      <section className="px-2">
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
          <AnimatePresence>
            {displayedCourses &&
              displayedCourses.length > 0 &&
              displayedCourses.map(course => {
                return <CourseCard key={course.node.id} course={course.node} />
              })}
          </AnimatePresence>
        </div>
      </section>
      {filteredCourses.length > 6 && <button>Plus</button>}
      <AncienCours />
    </Layout>
  )
}

CoursPage.propTypes = {}

export default CoursPage

export const query = graphql`
  query coursesPage {
    allStrapiCourse {
      edges {
        node {
          id
          title
          slug
          level {
            title
            slug
          }
          chapters {
            title
            content
          }
          featured_media {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`
